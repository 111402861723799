import Hero from '../components/Hero';
import FacialsComponent from '../components/FacialsComponent';
import PromoModal from '../components/PromoModal';

const Home = () => {
  return (
    <div>
      <Hero />
      <FacialsComponent />
      <PromoModal />
    </div>
  );
};

export default Home;
